@import '../../../assets/styles/_variables.module';

.titleSections {
  opacity: 0.7;
  letter-spacing: 0;
}

.footerWrapper {
  padding-top: 6rem;
}

.hrWrapper {
  border-color: #fff;
  border-width: 2px;
  opacity: 0.15;
}
//box-shadow: 0px 3px 37px #0000001C;

.socialMediaWrapper {
  position: relative;
  display: inline-block;
  margin: 0 5px 10px 5px;

  a {
    position: absolute;
    top: 3px;
    left: 5px;
  }

  svg {
    fill: $primaryColorMega;
  }

  &:hover {
    svg {
      fill: $secondaryColorMega;
      filter: drop-shadow(0px -3px 6px #ffffff45) drop-shadow(0px 8px 16px #8ab93459);
      -webkit-filter: drop-shadow(0px -3px 6px #ffffff45) drop-shadow(0px 8px 16px #8ab93459);
    }
  }
}
