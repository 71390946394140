@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.mg_steps {
  display: flex;
}

.inner_step {
  display: flex;
  flex: 1;
  flex-direction: column;

  .step_title {
    color: $gray-600;
    flex: 1;
  }

  &.active {
    .line {
      background-color: $primaryColorMega;
    }

    .step_title {
      color: $primaryColorMega;
    }
  }

  &.past {
    .line {
      background-color: $secondaryColorMega;
    }

    .step_title {
      color: $gray-600;
    }
  }

  .line {
    margin-top: 10px;
    height: 10px;
    background-color: $mega_light_gray;
  }
}

@include media-breakpoint-down(sm) {
  .step_title {
    font-size: 0.9rem;
    padding-right: 10px;
  }
}
