@import '../../../../assets/styles/_variables.module';
@import '../../../../assets/styles/_imports';

.wrapper {
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: $mega_light_gray;
  padding: 20px;
  border-radius: 6px;
  text-align: center;
  box-shadow: 0px 1px 4px #00000014;
  border: 1px solid #d3d3d3;
}
