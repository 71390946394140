@import '../../../assets/styles/_variables.module';

.megaNavbar {
  height: $headerHeight;
  box-shadow: 0px 3px 37px #0000001c;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 149;
}

.menuLogin {
  display: flex;
  font-family: 'Inter-Bold' !important;
}

.logoWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.menuWrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.logo {
  max-width: 183px;
}

.menuItem {
  padding: 0 0.8rem;
  display: flex;
}

.a_menuItem {
  color: #171825;
  letter-spacing: 0px;
  font: normal normal bold 14px/25px 'Inter-Bold';
}

.a_menuItem_active {
  color: $secondaryColorMega;
  text-decoration: underline;
}
