.summary_card {
  height: 100%;
}

.header {
  font-family: 'Futura-Bold', Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-size: 12px;
  font-weight: 900;
  margin-bottom: 10px;
  text-transform: uppercase;
  text-decoration: underline;
}