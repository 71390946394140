@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.wrapperInput {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px;
}

.tabRecarga {
  width: 33.3%;
  font-family: 'Inter-Bold';
  text-align: center;
  border-radius: 8px;
  padding: 10px 0;
  color: #8f979f;

  &:hover:not(.tabRecargaActive) {
    background: #f9f9f9 0% 0% no-repeat padding-box;
  }
}

.tabRecargaActive {
  background-color: #e1eee6;
  color: #263645;
}

.contact_row {
  display: flex;
  flex: 1;
  flex-direction: row;
  border-bottom: 1px $primaryColorMega solid;
  padding: 5px 0;
  cursor: pointer;
  border-bottom: 0;

  &:hover:not(.active) {
    background-color: $mega_light_gray;
  }

  &.active {
    background-color: $mega_light_gray_active;
  }

  .contact_right {
    align-self: center;
    padding: 0 10px;
  }

  .contact_center {
    flex: 1;
    flex-direction: column;
    align-self: center;

    h6 {
      font-size: 1rem;
      padding: 0;
      margin: 0;
    }

    small {
      padding: 0;
      margin: 0;
    }

    .contact_phone {
      font-size: 0.75rem;
      color: $gray600Mega;
    }
  }
}

.cost_wrapper {
  display: flex;
  flex-direction: row;
  // border-bottom: 1px solid $primaryColorMega;
  margin-top: 15px;
}

.subpage_menu_wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
}

.subpage_menu {
  border: 1px solid $primaryColorMega;
  border-radius: 10px;
  text-align: center;
  padding: 10px 12px;
  margin: 0 8px;
  background-color: $mega_light_gray;
  cursor: pointer;

  &:hover {
    text-transform: none;
    text-decoration: none;
    color: $primaryColorMega;
  }

  &.active {
    color: $secondaryColorMega;
    border: 1px solid $secondaryColorMega;
  }

  p {
    // font-family: 'Futura-Bold', Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    //   sans-serif;
    // font-size: 0.9rem;
    margin: 0;
    padding: 0;
  }
}
