@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.product {
  padding: 15px;
  // width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  margin-bottom: 25px;
  margin: 0 0 25px 0;

  &:last-child {
    margin-bottom: 0 !important;
  }

  &.checkout {
    padding: 20px 0;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    border-bottom: 1px solid #c5c5c5;

    &:last-child {
      border-bottom: 0 !important;
      padding-bottom: 0;
    }
  }
}

.quantity {
  font-size: 15px;
  font-weight: bold;
  padding: 0 15px 5px 15px;
}

@include media-breakpoint-up(lg) {
  .quantity {
    padding: 5px 12px 5px 0;
  }
}

.product_img {
  height: auto;
  width: 100%;
}

.product_info_detail {
  font-size: 0.8rem;
  color: $mega_light_pink;
  margin-left: 5px;
}
