@import '../../../assets/styles/_variables.module';

.title {
  font-size: 20px;
  font-family: 'Futura-Bold', Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
    sans-serif;
}

.cost_wrapper {
  background-color: $mega_light_gray;
  display: flex;
  flex-direction: row;
  padding: 20px;
  border-radius: 10px;

  h2 {
    color: $secondaryColorMega;
    font-family: 'Futura-Bold', Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue,
      sans-serif;
  }

  h2,
  h5 {
    margin: 0;
    padding: 0;
  }
}
