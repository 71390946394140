@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.wrapper {
  background-color: #fafafa;
  padding: 5rem 0;
}

.wrapperContent {
  width: 100%;
  min-height: 100px;
  border-radius: 10px;
  padding: 30px 20px;

  background-image: url(../../../assets/icons/abstract.svg);
  background-repeat: no-repeat;
  background-position: right center;

  &.secondaryWrapper {
    background-color: $secondaryColorMega;
    box-shadow: inset 0px -3px 6px #ffffff45, 0px 8px 16px #8ab93459;
  }

  &.infoWrapper {
    background-color: $infoColorMega;
    box-shadow: inset 0px -3px 6px #ffffff45, 0px 8px 16px #5586f759;
  }

  &.warningWrapper {
    background-color: $warningColorMega;
    box-shadow: inset 0px -3px 6px #ffffff45, 0px 8px 16px #ff985559;
  }
}

.secondaryWrapper {
}
.infoWrapper {
}
.warningWrapper {
}

.titleWrapper {
  width: 40%;
}

@include media-breakpoint-only(md) {
  .titleWrapper {
    width: 60%;
  }
}

@include media-breakpoint-only(sm) {
  .titleWrapper {
    padding: 0 10%;
    width: 100%;
  }
}

@include media-breakpoint-only(xs) {
  .wrapperContent {
    border-radius: 0;
    background-position: center center;
    background-size: cover;
  }

  .titleWrapper {
    padding: 0 15%;
    width: 100%;
  }
}
