@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.tab {
  padding: 5px 15px;
  text-align: center;
}

.activeTab {
  border-bottom: 3px solid $secondaryColorMega;
}

@include media-breakpoint-down(xl) {
  .tab-1 {
    width: 100%;
  }

  .tab-2 {
    width: 50%;
  }

  .tab-3 {
    width: 33.3%;
  }
}
