@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.sliderwrapper {
  margin: 15px;
  z-index: 1;
  display: flex;
  align-items: center;
}

.sliderrow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
}

.slidercolumn {
  margin: 20px;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;

  
}

@include media-breakpoint-only(md) {
  .sliderwrapper {
    min-height: 970px !important;
  }
}

@include media-breakpoint-up(lg) {
  .sliderwrapper {
    min-height: 600px;
  }

  .slidercolumn {
    height: auto;
  }
}

@include media-breakpoint-down(lg) {
  .sliderwrapper {
    min-height: 830px;
  }

  .slidercolumn {
    height: auto;
  }

  .sliderrow {
    flex-direction: column-reverse;
  }
}
