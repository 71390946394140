@import '../../../assets/styles/_variables.module';

.subtotal,
.total {
  font-size: 1.4rem;
  margin-bottom: 20px;
  text-align: right;

  :global(span) {
    font-weight: bold;
  }
}

.total {
  color: $secondaryColorMega;
  font-weight: bold;
}