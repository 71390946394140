@import '../../../assets/styles/_variables.module';

.wrapper {
  padding: 10px;
  padding-top: 20px;
  background-color: #ffedf2;
  border-radius: 15px;
  opacity: 1;
  margin-top: 10px;

  h6 {
    margin-left: 12px;
    margin-bottom: 10px;
  }
}

.slide {
  margin: 0px;
  padding: 15px;
  width: 100%;
  background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-color: #f8acc3;
  border-style: solid;
  border-width: 1px;
  border-radius: 15px;
  opacity: 1;
}

.swiper_button_next {
  position: relative;
  top: -230px;
  z-index: 3;
  width: 35px;
  height: 35px;
  background: #fe4a81 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #fe4a8157;
  border-radius: 50%;
  background-image: url(../../../assets/images/derch.png);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  margin-right: -10px;
}

.swiper_button_prev {
  position: relative;
  top: -230px;
  z-index: 3;
  width: 35px;
  height: 35px;
  background: #fe4a81 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 5px #fe4a8157;
  border-radius: 50%;
  background-image: url(../../../assets/images/izq.png);
  background-repeat: no-repeat;
  background-position: center;
  opacity: 1;
  margin-left: -10px;
}

.product_name {
  font-size: 13px;
  font-weight: 600;
  color: $primaryColorMega;
  margin: 5px;
}

.product_description {
  font-size: 12px;
  font-weight: normal;
  color: $gray600Mega;
  margin: 5px;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.product_price {
  font-size: 14px;
  font-weight: bold;
  color: #fe4a81;
  margin: 5px;
}

.product_image {
  margin-bottom: 10px;
  max-height: 300px;
  width: 100%;
}

.view_icon {
  position: absolute;
  z-index: 4;
  top: 30px;
  right: 30px;
}

.view_icon:hover {
  -webkit-filter: sepia(100%) hue-rotate(290deg) saturate(800%);
  filter: sepia(100%) hue-rotate(290deg) saturate(800%);
}
