@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.sectionWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px;
}

.labelBig {
  font: normal normal bold 18px/27px Inter-Bold;
  color: $primaryColorMega;
}

.labelSmall {
  font: normal normal bold 14px/25px Inter-Bold;
  color: $primaryColorMega;
}

.icon {
  margin-top: -3px;
}

.paymentRow {
  display: flex;
  flex-direction: row;
  padding: 5px 0;

  &.total {
    .label,
    .price {
      font-family: Inter-Bold;
    }
  }

  .label {
    flex: 1 1 auto !important;
    text-align: left;
  }

  .price {
    flex: 1 1 auto !important;
    text-align: right;

    &.highlight {
      color: $secondaryColorMega;
    }
  }

  &.border {
    border-bottom: 1px solid #c5c5c5;
  }
}

.productImg {
  max-width: 50px !important;
}

.productLine {
  & + .productLine {
    border-top: 1px solid #c5c5c5;
    margin-top: 15px;
    padding-top: 15px;
  }
}

@include media-breakpoint-up(lg) {
  .productImg {
    max-width: 115px !important;
  }
}

@include media-breakpoint-only(md) {
  .productImg {
    max-width: 80px !important;
  }
}

@include media-breakpoint-only(sm) {
  .productImg {
    max-width: 70px !important;
  }
}
