@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.contact_table {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flagContact {
  display: block;
  width: 45px !important;
  height: 45px !important;
}

.contact_table_wrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px;
  margin: 0 0 15px 0;
}

.contact_table_row {
  flex: 1;
  display: flex;
  padding-top: 10px;
  padding-bottom: 10px;

  &.thr {
    padding: 15px;
  }
}

.contact_table_column {
  flex: 1;

  align-self: center;
  justify-self: center;

  &.th {
    font-weight: 600;
    color: #8f979f;
  }

  &.price {
    color: $secondaryColorMega;
  }

  &.tarifa {
    max-width: none;
    text-align: left;
  }
}

.nauta_section {
  margin-top: 30px;
}

.buttons_section {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.phone_link {
  font-size: 1rem;
}

.buttonProfileEditar {
  width: 48%;
  margin-right: 1%;
}

.buttonProfileEliminar {
  width: 48%;
  margin-left: 1%;
}

.columnWrapper {
  padding: 0 10px;

  &:first-child {
    padding-left: 10px;
  }
}

.columnTitle {
  color: #5f6368;
  text-align: left;
}

.columnValue {
  font-size: 0.9rem;
  color: $primaryColorMega;

  text-align: left;
  width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@include media-breakpoint-up(lg) {
  .columnWrapper {
    &:first-child {
      padding-left: 0;
    }
  }
}

@include media-breakpoint-down(sm) {
  .phone_link {
    font-size: 0.8rem;
  }

  .contact_table_column {
    &.tarifa {
      max-width: 90px;
      text-align: right;
    }
  }
}
