@import '../../../assets/styles/variables.module';

.cart_widget {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 2px 10px;
  border-radius: 8px;
  font-family: 'Inter-Bold';
  position: relative;

  &:hover {
    background-color: #8ab93426;
  }

  &:after {
    content: '';
    position: absolute;
    right: 5px;
    top: 5px;
    height: 5px;
    width: 5px;
    background-color: $dangerMega;
    opacity: 0.7;
    border-radius: 50%;
  }
}

.sidebar_body_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sidebar_body_items_container {
  flex: 1;
  overflow-y: auto;
  padding: 0 10px;

  & {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.sidebar_body_content_item {
  display: flex;
  align-items: center;
  // margin: 10px 0;
  padding: 15px 0;
  border-bottom: 1px solid #c5c5c5;
}

.sidebar_body_content_item + .sidebar_body_content_item {
  border-top: 1px solid $mega_light_gray;
  padding-top: 10px;
}

.sidebar_body_content_item_img {
  width: 80px;
  height: auto;
}

.sidebar_body_content_item_details {
  flex: 1;
  padding: 10px;
  font-size: 13px;
}

.sidebar_body_content_item_price {
  color: $secondaryColorMega;
  font-family: 'Inter-Bold';
  font-size: 15px;
}

.footer {
  padding-top: 20px;
  .btn {
    width: 100%;
  }
}

.cart_total {
  background: $mega_light_gray;
  border-radius: 10px;
  font-size: 30px;
  margin-bottom: 10px;
  text-align: right;
  padding: 5px;
}

.cart_total::before {
  content: 'Total a pagar:';
  font-size: 20px;
}

.red_square {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid $dangerMega ;
  width: 25px;
  height: 25px;
  text-align: center;
}

.green_square {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid $secondaryColorMega ;
  width: 25px;
  height: 25px;
  text-align: center;
}

.square_text {
  cursor: pointer;
  font-size: 25px;
  font-weight: bold;
  line-height: 20px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.square_minitext {
  cursor: pointer;
  font-size: 20px;
  font-weight: bold;
  line-height: 20px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */

  color: $gray600Mega;
}

.quantity {
  font-size: 15px;
  font-weight: bold;
}

.product_info_detail {
  font-size: 0.8rem;
  color: $mega_light_pink;
}