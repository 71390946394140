@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.logo {
  max-width: 183px;
}

.headerBorderWrapper {
  height: 8px;
  background-color: #e1eee7;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 0;
}

.paymentWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 20px;
  margin-bottom: 20px;
}

.lineFundWrapper {
  display: flex;
  width: 100%;
  flex-direction: row;
}

.backButton {
  margin-right: 15px;
  padding-left: 20px;
  padding-right: 20px;
}

.payButton {
  padding-left: 20px;
  padding-right: 20px;
}

.wrapperPaySafe {
  background: #e1eee733 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
  opacity: 1;
  padding: 20px;
  max-width: 300px;
}

.megaInfo {
  font: normal normal normal 0.875rem/1.688rem 'Inter-Regular';
  letter-spacing: 0px;
  color: $primaryColorMega;
  opacity: 0.8;
  padding-bottom: 1rem !important;
}

.hm_100 {
  height: 100%;
}

.infoPago {
  font-size: 1.25rem;
}

.pPago {
  font-size: 1rem;
}

.referralWrapper {
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3d3;
  border-radius: 6px;
  padding: 10px;
  margin-top: 20px;

  input {
    margin-left: 0 !important;
    margin-right: 5px !important;
  }

  label {
    margin-top: 3px;
  }
}

@include media-breakpoint-down(lg) {
  .continueButton {
    width: 100%;
  }

  .backButton {
    width: 49%;
    margin-right: 2%;
  }

  .payButton {
    width: 49%;
  }

  .hm_100 {
    height: auto !important;
  }

  .infoPago {
    font-size: 1rem;
  }

  .pPago {
    font-size: 0.875rem;
  }
}

.description_title {
  font-size: 14px;
}

.description_frame {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  padding: 10px 15px;
  background: #e1eee733 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  opacity: 1;
  width: 100%;
}

.description_line {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.dot {
  margin: 5px;
  margin-right: 20px;
  height: 5px;
  width: 5px;
  background-color: $secondaryColorMega;
  border-radius: 50%;
}

.description_left {
  width: 5%;
}

.description_text {
  font-size: 12px;
  color: $gray600Mega;
  text-align: justify;
  text-justify: inter-word;
}
