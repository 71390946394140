@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.textWrapper {
  font-size: 0.9rem;

}

.productPrice {
  color: $mega_light_pink;
  margin-left: 10px;
}

.productImg {
  height: auto;
  width: 80px;
}
