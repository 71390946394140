@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.menuWrapper {
  font-size: 1rem;
  font-family: 'Inter-Bold';
}

.menuItemWrapper {
  width: 280px !important;
  max-width: 280px !important;
  padding: 6px 10px 6px 20px !important;
}

.itemWrapperOutside {
}

.divider {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.wrapperMenuItems {
  background: #e1eee77a 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
  padding: 8px 0;
  margin: 6px 10px;

  max-width: 280px !important;
}

@include media-breakpoint-down(md) {
  .menuItemWrapper {
    width: 100% !important;
    max-width: 100% !important;
  }
}
