@import '../../../assets/styles/variables.module';

.top_up_widget {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 40px;
  padding: 2px 10px;
  border-radius: 8px;
  font-family: 'Inter-Bold';
  position: relative;

  &:hover {
    background-color: #8ab93426;
  }

  &:after {
    content: '';
    position: absolute;
    right: 5px;
    top: 5px;
    height: 5px;
    width: 5px;
    background-color: $dangerMega;
    opacity: 0.7;
    border-radius: 50%;
  }
}

.sidebar_body_content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.sidebar_body_items_container {
  flex: 1;
  overflow-y: auto;

  & {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #888;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
}

.sidebar_body_content_item {
  align-items: center;
  display: flex;
  margin: 10px 0;
  padding: 0 10px;

  &:nth-child(even) {
    background: $mega_light_gray;
  }
}

.sidebar_body_content_item_details {
  flex: 1;
  padding: 10px;
}

.footer {
  padding-top: 20px;
  .btn {
    width: 100%;
  }
}

.cart_total {
  background: $mega_light_gray;
  border-radius: 10px;
  font-size: 30px;
  margin-bottom: 10px;
  text-align: right;
  padding: 5px;
}

.cart_total::before {
  content: 'Total a pagar:';
  font-size: 20px;
}
