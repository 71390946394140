@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.main_background {
  background-color: $mega_light_gray;
}

.promoBanner {
  position: relative;
  width: 100%;
  height: 100%;
}

.promoBannerBg {
  z-index: 1;
  position: absolute;
  background: #ffedf2;
  top: 0;
  left: 0;
  right: 0;
  height: 90%;
}

.area_title {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 1px;
}

@include media-breakpoint-up(lg) {
  .promoBannerBg {
    height: 50%;
  }
}
