@import '../../../assets/styles/_variables.module';

.cart_category_header {
  align-items: center;
  background-color: $mega_light_gray;
  border-radius: 10px;
  display: flex;
  padding: 10px;
}

.emptySubtitleH5 {
  color: #c5c5c5;
  font-family: 'Inter-Light';
}

.emptySubtitleP {
  color: #c5c5c5;
}

.wrapperList {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px;

  &.hide_cta {
    background: transparent !important;
    box-shadow: none !important;
  }
}

.lineTitle {
  font-family: 'Inter-Bold';
}

.lineRecarga {
  font-family: 'Inter-Bold';
  font-size: 1.15rem;
}


.productsPromoDate {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #fffccb;
  border: 1px solid #f3d88c;
  padding-left: 15px;   
  padding: 5px;   
  border-radius: 5px;
  font-size: 12px;
}
