@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.product_image_slider {
  // margin-left: 30px;
  margin-top: 10px;
  overflow: hidden;
  width: 100%;
  text-align: center;

  :global(.swiper-pagination-bullet-active) {
    background-color: $primaryColorMega;
  }

  .product_image_slider_img {
    max-width: 100%;
    margin: 0 auto;
  }
}

@include media-breakpoint-up(md) {
  .product_image_slider {
    .product_image_slider_img {
      max-width: 350px;
      margin: 0 auto;
    }
  }
}
