@import '../../../assets/styles/_variables.module';

.wrapper {
  background-color: #eef3fe;
  padding: 4rem 0;
}

.priceWrapper {
  margin-top: 10px;
  width: 220px;
  position: relative;
}

.priceContentWrapper {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  padding-top: 10px;
}

.infoContentWrapper {
  margin-top: -5px;
  margin-left: 8px;
}

.priceWrapper2 {
  margin-top: 10px;
  width: 270px;
  position: relative;

  filter: drop-shadow(0px -3px 6px #ffffff45) drop-shadow(0px 8px 16px #5586f759);
  -webkit-filter: drop-shadow(0px -3px 6px #ffffff45) drop-shadow(0px 8px 16px #5586f759);
}

.infoContentWrapper2 {
  margin-top: -5px;
  margin-left: 8px;
}

.pDesc {
  margin: 0;
  font-weight: bold;
  font-family: 'Inter-ExtraBold';
}

.pDescTime {
  margin-top: -5px;
}

.paqueteBasico {
  border: 4px solid $infoColorMega;
  border-radius: 25px;
  padding: 15px;
  width: 300px;
}

.paquetePopular {
  border: 4px solid $primaryColorMega;
  background-color: $primaryColorMega;
  border-radius: 25px;
  padding: 15px;
  width: 300px;
  color: #fff;
}

.priceHeight {
  display: flex;
  // line-height: 2.25rem !important;
}

.listItemsRecarga {
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 8px;
  }
}

.mas {
  margin-top: -3px;
  margin-right: 5px;
}
