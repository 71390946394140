.wrapperHr {
  position: relative;

  label {
    display: inline-block;
    position: absolute;
    bottom: -10px;
    left: 48%;
    background-color: #fff;
    z-index: 1;
    padding: 0 2px;

    &:after {
      content: ' ';
      background-color: #fff;
      width: 100%;
      height: 13px;
      position: absolute;
      left: 0;
      bottom: 5px;
      z-index: -1;
    }
  }
}
