@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.paymentMethod {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;
  margin-bottom: 15px;
  cursor: pointer;
}

.selected {
  background: #e1eee780 0% 0% no-repeat padding-box;
  border: 1px solid #8ab934;
}

.cc {
  color: #616161;
}

.ccButtons {
  color: $primaryColorMega !important;
  text-decoration: none !important;
}
