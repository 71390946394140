@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.wrapper {
  background-color: #fafafa;
  padding: 5rem 0;
}

.listItemWrapper {
  display: flex;
  flex-direction: row;

  &:hover {
    h6 {
      color: $secondaryColorMega;
    }

    .iconWrapper {
      transform: scale(1.1);
    }
  }
}

.listDescriptionWrapper {
  width: 50%;
  padding-left: 20px;
}

.iconWrapper {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  transition: all 0.2s ease-in-out;

  &.dividerWrap {
    display: flex;
    height: 50px;
    justify-content: center;
    padding: 3px 0 5px 0;
  }
}

.iconBackground {
  position: relative;
  width: 80px;
}

.iconTopWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.iconTop {
  position: relative;
  width: 50px;
  margin: 15px 15px;
}

.divider {
  display: block;
  height: 100%;
  width: 2px;
  background-color: #c5c5c5;
}

.wrapperAppDownload {
  display: flex;
  flex-direction: row;
}

@include media-breakpoint-down(lg) {
  .wrapperAppDownload {
    align-items: center;
    justify-content: center;
  }

  .listDescriptionWrapper {
    width: 70%;
  }
}
