@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.wrapper {
  background-color: #e1eee7;
  padding: 5rem 0 10rem 0;
}

.wrapperColumns {
  margin-top: -7rem;
  margin-bottom: 5rem;
}

.imgContact {
  max-width: 120px;
}

.servicesSvg {
  filter: drop-shadow(0px 8px 16px #2636451a);
  -webkit-filter: drop-shadow(0px 8px 16px #2636451a);
}
