@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.report_btn {
  min-width: 120px;
  margin-right: 15px;
}

.contactWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px 0;
}

.contactRowWrapper {
  padding: 0 15px;

  &.activeRow {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 15%;
      height: 70%;
      border-left: 5px solid #8ab934;
      opacity: 0.7;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.contact_link {
  &:hover {
    color: $primaryColorMega;
  }
}

.contact_row {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 10px 0;
  cursor: pointer;
  margin-bottom: 10px;
  border-radius: 8px;

  &.alone {
    margin-bottom: 0;
  }

  &.no_border {
    border-bottom: 0;
  }

  &:hover:not(.active) {
    background-color: $mega_light_gray;
  }

  &.active {
    background-color: #f3f6f9;
    font-weight: bold;
  }

  .contact_right {
    display: flex;
    flex-direction: column;
    padding: 0 10px;
    justify-content: center;
  }

  .contact_center {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;

    &.active {
      font-family: 'Inter-SemiBold';
    }

    .contact_phone {
      font-size: 0.75rem;
      color: $gray600Mega;
    }
  }

  .contact_left {
    align-self: center;
    padding-right: 10px;
  }
}

.activeIconRow {
  fill: $secondaryColorMega;
}

.inActiveIconRow {
  fill: #abadc4;
}

.wrapperDeslizar {
  border: 1px solid #e2e2e2;
  background: #fef9e9 0% 0% no-repeat padding-box;
  border-radius: 6px;
  opacity: 1;
  color: $primaryColorMega;
  padding: 15px;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  p {
    margin: 0;
    padding: 0;
  }
}
