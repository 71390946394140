@import '../../../assets/styles/_variables.module';

.menu_icon {
  margin-left: 7px;
  margin-bottom: 2px;
}

.menu_wrapper {
  display: flex;
  flex-direction: row;
}

.menu_item {
  flex-grow: 1;
  flex-basis: 0;
  justify-content: center;
  align-items: center;
  font-weight: bold;

  &:last-child {
    border-right: 0;
  }
}

.menuItemActive {
  border-radius: 8px;
  background-color: #ffffff;
}

// @include media-breakpoint-down(lg) {
//   . {
//     background-color: red;
//   }
// }
