@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

$maxWidthButtons: 160px;
$maxHeightButtons: 180px;

.buttonSelector {
  width: $maxWidthButtons;
  max-width: $maxWidthButtons;
  max-height: $maxHeightButtons;
  height: $maxHeightButtons;
}

.methodWrapper {
  border: 1px solid #c5c5c5;
  border-radius: 10px;
  opacity: 1;
  padding: 15px;

  &:hover {
    border: 1px solid $secondaryColorMega;
  }
}
