@import '../../../assets/styles/_variables.module';

.slide {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  margin: 0px;
  padding: 15px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  position: relative;

  &:hover {
    box-shadow: 0px 8px 10px #00004717;
  }
}

.link {
  &:hover {
    color: inherit !important;

    h6 {
      text-decoration: none;
    }
  }
}

.imgWrapper {
  margin: 0 -15px;
  padding: 0 15px;
  position: relative;
}

.outOfStockBanner {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $dangerMega;
  color: #ffffff;
  text-align: center;
  padding: 5px 0;
}

.product_name {
  margin-left: 0 !important;
}

.product_description {
  font-size: 0.937rem;
  font-weight: normal;
  color: $gray600Mega;
  overflow: hidden;
  display: block;
  display: -webkit-box;
  max-width: 100%;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.product_price {
  color: $dangerMega;
  margin-top: 5px;
  margin-bottom: 0;
  margin-left: 0 !important;
}

.product_image {
  margin-bottom: 10px;
  max-height: 300px;
  width: 100%;
}
product_price .store_product_card__button {
  display: flex;
  justify-content: flex-end;
  align-content: flex-end;
}

.outOfStock {
  .product_image,
  .product_name,
  .product_description,
  .product_price {
    opacity: 0.5;
  }
}
