.wrapperPromocion {
  background: transparent linear-gradient(180deg, #e4f6c3 0%, #e1eee7 100%) 0% 0% no-repeat
    padding-box;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
}

.wrapperNow {
  background: #e1eee780 0% 0% no-repeat padding-box;
  border: 1px solid #d3d3d3;
  border-radius: 10px;
  padding: 15px;
  cursor: pointer;
}
