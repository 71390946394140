.frame {
  margin: 10px;
  padding: 10px 20px 20px 20px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
}

.frameMenu {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

.mega_radio {
  z-index: 1500;
  position: relative;

  input {
    position: relative;
    z-index: 1500;
  }

  label {
    position: relative;
    z-index: 1500;
  }
}

.section_title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 20px;
}

.horizontal_separator {
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  height: 1px;
  background: #c5c5c5 0% 0% no-repeat padding-box;
  opacity: 0.5;
}

.category {
  cursor: pointer;
  font-size: 14px;
  .text {
    margin-left: 5px;
    word-wrap: normal;
  }
}

.category:hover {
  font-weight: bold;
  .image {
    -webkit-filter: sepia(100%) hue-rotate(290deg) saturate(1200%);
    filter: sepia(100%) hue-rotate(290deg) saturate(1200%);
  }
  .text {
    text-decoration: underline;
  }
}

.categoryActive {
  font-weight: bold;
  .image {
    -webkit-filter: sepia(100%) hue-rotate(290deg) saturate(1200%);
    filter: sepia(100%) hue-rotate(290deg) saturate(1200%);
  }
  .text {
    text-decoration: underline;
  }
}

.form_check_label {
  margin-left: 5px;
  vertical-align: middle;
  margin-bottom: 10px;
}
