.wrapper {
  padding: 13px 0px 12px 10px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-top: 1px solid #c5c5c5;
  border-bottom: 1px solid #c5c5c5;
  z-index: 148;
  position: fixed;
  top: 64px;
  left: 0;
  right: 0;
}
