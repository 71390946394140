@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.wrapper_empty {
  background-color: $mega_light_gray;
  padding-top: 40px;
  padding-bottom: 40px;
  border-radius: 5px;
}

.contactWrapper {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px 0;
}

.contactRowWrapper {
  padding: 0 15px;

  &.activeRow {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      left: 0px;
      top: 15%;
      height: 70%;
      border-left: 5px solid #8ab934;
      opacity: 0.7;
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }
}

.columns {
  display: flex;
  flex-direction: row;
}

.step {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: center;
  justify-content: center;

  h6 {
    text-align: center;
    margin-top: 10px;
    font-size: 0.95rem;
  }
}

.step * {
  align-self: center;
  justify-content: center;
}

.button_wrapper {
  text-align: center;
  margin-top: 30px;
}

.contact_link {
  &:hover {
    color: $primaryColorMega;
  }
}

.contact_row {
  display: flex;
  flex: 1;
  flex-direction: row;
  padding: 5px 0;
  cursor: pointer;
  margin-bottom: 10px;

  &.no_border {
    border-bottom: 0;
  }

  &:hover:not(.active) {
    background-color: $mega_light_gray;
  }

  &.active {
    background-color: #f3f6f9;
  }

  .contact_right {
    align-self: top;
    padding: 5px 10px 0 10px;
  }

  .contact_center {
    flex: 1;
    flex-direction: column;

    h6,
    small {
      padding: 0;
      margin: 0;
    }

    .contact_phone {
      font-size: 0.75rem;
      color: $gray600Mega;
    }
  }

  .contact_left {
    align-self: center;
    padding-right: 10px;
  }
}

.buttonAdd {
  margin: 0 15px;
}

@include media-breakpoint-down(sm) {
  .columns {
    flex-direction: column;
  }

  .step {
    margin-top: 30px;
  }
}
