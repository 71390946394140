@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.hm_100 {
  height: 100%;
}

.wrapperPaySafe {
  background: #e1eee733 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 15px;
  opacity: 1;
  padding: 20px;
  max-width: 300px;
}

.megaInfo {
  font: normal normal normal 0.875rem/1.688rem 'Inter-Regular';
  letter-spacing: 0px;
  color: $primaryColorMega;
  opacity: 0.8;
  padding-bottom: 1rem !important;
}

@include media-breakpoint-down(lg) {
  .hm_100 {
    height: auto !important;
  }
}
