@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.productsWrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  flex-wrap: wrap;
}

.productsPromoDate {
  display: flex;
  flex-direction: row;
  width: 100%;
  background: #fffccb;
  border: 1px solid #f3d88c;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 12px;
}

.product {
  width: 32.3%;
  background: #e1eee71a 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  opacity: 1;
  padding: 10px;
  margin-bottom: 10px;
  position: relative;  
}

.productSelected {
  background: #fbfdf6 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 12px #8ab93429;
  border: 2px solid #8ab934;
}

.productSelectedIcon {
  position: absolute;
  top: 10px;
  right: 10px;
}

.seeMore {
  text-align: center;
  font-family: 'Inter-Bold';
  display: flex;
  justify-content: center;
  align-items: center;
}

.x {
  font-family: 'Inter-Bold';
  font-size: 1.2rem;
  margin-left: 4px;
}

@include media-breakpoint-down(xl) {
  .product {
    width: 49%;
  }
}

@include media-breakpoint-down(lg) {
  .product {
    width: 32.3%;
  }
}

@include media-breakpoint-down(md) {
  .product {
    width: 48%;
  }
}
