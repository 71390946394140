@import '../assets/styles/_variables.module';
@import '../assets/styles/_imports';

.contact_row {
  border-bottom: 0;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex: 1;

  .mt-ish {
    margin-top: 3px;
  }

  &:hover:not(.active) {
    background-color: $mega_light_gray;
  }

  &.active {
    background-color: $mega_light_gray_active;
  }

  .contact_right {
    align-self: center;
    padding: 0 10px;
  }

  .contact_center {
    align-self: center;
    flex-direction: column;
    flex: 1;

    h6 {
      font-size: 1rem;
      margin: 0;
      padding: 0;
    }

    small {
      margin: 0;
      padding: 0;
    }

    .contact_phone {
      color: $gray600Mega;
      font-size: 0.75rem;
    }
  }
}

.imageactive {
  -webkit-filter: sepia(100%) hue-rotate(290deg) saturate(1200%);
  filter: sepia(100%) hue-rotate(290deg) saturate(1200%);
}
