.wrapper {
  padding: 5rem 0;
}

.iconWrapper {
  display: flex;
  position: relative;
  width: 80px;
  height: 80px;
  clear: both;
}

.iconTopWrapper {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.iconTop {
  position: relative;
  width: 50px;
  margin: 15px 15px;
}
