@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.active_notif {
  background-color: green;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  opacity: 0.5;
  position: relative;
}

.active_notif_center {
  background-color: black;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.inside_notif {
  position: absolute;
  top: 3.5px;
  left: 3px;
  opacity: 0.7;
}

.outside_notif {
  margin: 0 3px;
}

.referFriend {
  min-width: 150px;
}

.profileDataNotif {
  color: #5f6368;
}

.buttonProfileSaldo {
  width: 48%;
  margin-right: 1%;
}

.buttonProfileCuenta {
  width: 48%;
  margin-left: 1%;
}

.h5Saldo {
  font: normal normal 400 1.5rem/2rem 'Inter-ExtraBold' !important;
}

@include media-breakpoint-up(lg) {
  .buttonProfileCuenta {
    width: auto;
    margin-left: 0;
  }
}
