@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.wrapper {
  background-color: #fafafa;
  padding: 5rem 0;
}

.reviewHeader {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.reviewHeaderImageWrapper {
  display: flex;
  width: 50px;
  height: 50px;
}

.reviewHeaderContentWrapper {
  padding-left: 10px;
  width: 100%;
}

@include media-breakpoint-down(md) {
  .reviewHeaderImageWrapper {
    width: 40%;
    justify-content: flex-end;
  }

  .reviewHeaderContentWrapper {
    width: 60%;
    justify-content: flex-start;
  }

  .imageAbs {
    width: 40%;
  }
}
