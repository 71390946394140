@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.button {
  width: 100%;
}

@include media-breakpoint-up(md) {
  .button {
    max-width: 150px;
  }
}