@import '../../../assets/styles/_variables.module';

.frame {
  flex: 1;
  margin: 0px;
  padding: 15px;
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
}

.title {
  font-size: medium;
  font-family: 'Inter-ExtraBold';
}
