@font-face {
  font-family: 'Inter-Black';
  src: url('../fonts/Inter-Black.woff2') format('woff2'),
    url('../fonts/Inter-Black.woff') format('woff'),
    url('../fonts/Inter-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Black';
  src: url('../fonts/Inter-Black_1.woff2') format('woff2'),
    url('../fonts/Inter-Black_1.woff') format('woff'),
    url('../fonts/Inter-Black_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Black';
  src: url('../fonts/Inter-Black_2.woff2') format('woff2'),
    url('../fonts/Inter-Black_2.woff') format('woff'),
    url('../fonts/Inter-Black_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../fonts/Inter-Bold.woff2') format('woff2'),
    url('../fonts/Inter-Bold.woff') format('woff'),
    url('../fonts/Inter-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../fonts/Inter-Bold_1.woff2') format('woff2'),
    url('../fonts/Inter-Bold_1.woff') format('woff'),
    url('../fonts/Inter-Bold_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Bold';
  src: url('../fonts/Inter-Bold_2.woff2') format('woff2'),
    url('../fonts/Inter-Bold_2.woff') format('woff'),
    url('../fonts/Inter-Bold_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('../fonts/Inter-ExtraBold.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBold.woff') format('woff'),
    url('../fonts/Inter-ExtraBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('../fonts/Inter-ExtraBold_1.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBold_1.woff') format('woff'),
    url('../fonts/Inter-ExtraBold_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-ExtraBold';
  src: url('../fonts/Inter-ExtraBold_2.woff2') format('woff2'),
    url('../fonts/Inter-ExtraBold_2.woff') format('woff'),
    url('../fonts/Inter-ExtraBold_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../fonts/Inter-ExtraLight.woff2') format('woff2'),
    url('../fonts/Inter-ExtraLight.woff') format('woff'),
    url('../fonts/Inter-ExtraLight.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../fonts/Inter-ExtraLight_1.woff2') format('woff2'),
    url('../fonts/Inter-ExtraLight_1.woff') format('woff'),
    url('../fonts/Inter-ExtraLight_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-ExtraLight';
  src: url('../fonts/Inter-ExtraLight_2.woff2') format('woff2'),
    url('../fonts/Inter-ExtraLight_2.woff') format('woff'),
    url('../fonts/Inter-ExtraLight_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../fonts/Inter-Light.woff2') format('woff2'),
    url('../fonts/Inter-Light.woff') format('woff'),
    url('../fonts/Inter-Light.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../fonts/Inter-Light_1.woff2') format('woff2'),
    url('../fonts/Inter-Light_1.woff') format('woff'),
    url('../fonts/Inter-Light_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Light';
  src: url('../fonts/Inter-Light_2.woff2') format('woff2'),
    url('../fonts/Inter-Light_2.woff') format('woff'),
    url('../fonts/Inter-Light_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter-Medium.woff2') format('woff2'),
    url('../fonts/Inter-Medium.woff') format('woff'),
    url('../fonts/Inter-Medium.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter-Medium_1.woff2') format('woff2'),
    url('../fonts/Inter-Medium_1.woff') format('woff'),
    url('../fonts/Inter-Medium_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Medium';
  src: url('../fonts/Inter-Medium_2.woff2') format('woff2'),
    url('../fonts/Inter-Medium_2.woff') format('woff'),
    url('../fonts/Inter-Medium_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../fonts/Inter-Regular.woff2') format('woff2'),
    url('../fonts/Inter-Regular.woff') format('woff'),
    url('../fonts/Inter-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../fonts/Inter-Regular_1.woff2') format('woff2'),
    url('../fonts/Inter-Regular_1.woff') format('woff'),
    url('../fonts/Inter-Regular_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Regular';
  src: url('../fonts/Inter-Regular_2.woff2') format('woff2'),
    url('../fonts/Inter-Regular_2.woff') format('woff'),
    url('../fonts/Inter-Regular_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold.woff') format('woff'),
    url('../fonts/Inter-SemiBold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../fonts/Inter-SemiBold_1.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold_1.woff') format('woff'),
    url('../fonts/Inter-SemiBold_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('../fonts/Inter-SemiBold_2.woff2') format('woff2'),
    url('../fonts/Inter-SemiBold_2.woff') format('woff'),
    url('../fonts/Inter-SemiBold_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Thin';
  src: url('../fonts/Inter-Thin.woff2') format('woff2'),
    url('../fonts/Inter-Thin.woff') format('woff'),
    url('../fonts/Inter-Thin.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Thin';
  src: url('../fonts/Inter-Thin_1.woff2') format('woff2'),
    url('../fonts/Inter-Thin_1.woff') format('woff'),
    url('../fonts/Inter-Thin_1.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Inter-Thin';
  src: url('../fonts/Inter-Thin_2.woff2') format('woff2'),
    url('../fonts/Inter-Thin_2.woff') format('woff'),
    url('../fonts/Inter-Thin_2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
