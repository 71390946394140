.textTitle {
  margin-top: -8px;
}

.inputArea {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px;
}

.infoMessage {
  background: #e1eee733 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  padding: 15px;
  width: 100%;
}
