@import '../../../../assets/styles/_variables.module';
@import '../../../../assets/styles/_imports';

.phoneWrapper {
  margin-top: 20px;
  margin-bottom: 30px;
  background-color: $mega_light_gray;
  padding: 20px;
  border-radius: 6px;
  position: relative;
  max-height: 64px;
  border: 1px solid #d3d3d3;
  box-shadow: 0px 1px 4px #00000014;
}

.backArrow {
  position: absolute;
  left: 5px;
  bottom: 19.5px;
}
