@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.main_background {
  background-color: $mega_light_gray;
}

.wrapperHeader {
  padding: 4rem 0 7rem 0;
  background-color: #e1eee7;
}

.priceWrapper {
  margin-top: 10px;
  width: 250px;
  position: relative;
}

.priceContentWrapper {
  padding: 15px 0;
}

.pPriceDesc {
  margin-top: -5px;
}

.rateContainer {
  margin-top: -75px;
  margin-bottom: 4rem;
}

.rateWrapper {
  background-color: $primaryColorMega;
  min-height: 150px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 30px;
}

.rateTitleWrapper {
}

.ratePriceWrapper {
  min-height: 115px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 15px;
}
.wrapperSelect1 {
  flex: 1 0 30%;
  box-sizing: border-box;
}

.wrapperSelect2 {
  flex: 1 0 30%;
  box-sizing: border-box;
  padding-left: 20px;
}

.wrapperSelect3 {
  flex: 1 0 25%;
  box-sizing: border-box;
  padding-left: 20px;

  p {
    margin: -10px 0 0 0;
  }

  & > div {
    margin: -10px 0 0 0;
  }
}

.opac {
  opacity: 0.2;
}

@include media-breakpoint-down(sm) {
  .rateContainer {
    background-color: $primaryColorMega;
  }
}
