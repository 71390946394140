@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.wrapper {
  height: 8px;
  width: 100%;
  border-radius: 4px;
  background: #ebebeb 0% 0% no-repeat padding-box;
  display: flex;
  flex-direction: row;
  gap: 0;
  z-index: 1000;
}

.wrapperContent {
  display: flex;
  flex-direction: row;
  gap: 0;
}

.step {
  height: 8px;
  flex-basis: 100%;
  z-index: 1000;
}

.stepActive {
  border-radius: 4px;
  background: transparent linear-gradient(90deg, #e1eee7 0%, $secondaryColorMega 100%) 0% 0%
    no-repeat padding-box;
  border-radius: 4px;
  flex: 0 0 20%;
  position: relative;

  &::after {
    content: ' ';
    width: 4px;
    height: 4px;
    border-radius: 2px;
    background-color: #fff;
    position: absolute;
    right: 2px;
    top: 2px;
  }
}

.stepTitle {
}

.stepTitleOld {
  color: $secondaryColorMega;
  font-weight: bold;
}

.stepTitleActive {
  font-weight: bold;
}
.stepTitleInactive {
  opacity: 0.5;
}

@include media-breakpoint-down(sm) {
  .stepTitle {
    font-size: 0.87rem;
  }
}
