@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.pTerms {
  font-size: 0.813rem;
  color: #8f979f;
}

.buttonWrapper {
  width: 50%;
  margin-left: 25%;
}

@include media-breakpoint-down(md) {
  .buttonWrapper {
    width: 100%;
    margin-left: 0;
  }
}
