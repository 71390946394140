@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.priceContainer {
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  padding: 0;
}

.priceWrapper {
  display: flex;
  padding: 1rem 1rem 0 0;

  p {
    font-size: 0.8rem;
  }
}

.price {
  background: #e1eee71a 0% 0% no-repeat padding-box;
  border: 1px solid #e2e2e2;
  border-radius: 10px;
  opacity: 1;
  padding: 11px;
  display: inline-block;
  width: 100%;
  position: relative;
  cursor: pointer;

  .selectedIcon {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  .priceWrapper {
    width: 50%;

    &:nth-child(even) {
      padding-right: 0 !important;
    }
  }
}

@include media-breakpoint-up(lg) {
  .priceWrapper {
    width: 33.3333%;

    &:nth-child(3) {
      padding-right: 0;
    }
  }
}

.selected {
  background: #fbfdf6 0% 0% no-repeat padding-box;
  box-shadow: 0px 5px 12px #8ab93429;
  border: 2px solid #8ab934;
  padding: 10px;

  .selectedIcon {
    display: block;
    position: absolute;
    right: 10px;
    top: 12px;
  }
}
