$slider_bg_llamadas: #5fc6c3;
$slider_bg_recargas: #e98bbd;
$slider_bg_nauta: #f9a255;
$slider_bg_sms: #5ae89c;

$primaryColorMega: #263645;
$secondaryColorMega: #8ab934;
$successColorMega: #e4f6c3;
$infoColorMega: #5586f7;
$warningColorMega: #ff9855;
$gray600Mega: #6c757d;
$dangerMega: #fe4a81;
$mega_light_pink: #fe4a81;
$mega_light_gray: #f9f9f9;
$mega_light_gray_active: darken($mega_light_gray, 10%);
$mega_light_gray_menu: darken($mega_light_gray, 20%);
$mega_white: #fff;

$headerHeight: 64px;

$theme-colors: (
  'primary': $primaryColorMega,
  'secondary': $secondaryColorMega,
  'success': $successColorMega,
  'info': $infoColorMega,
  'warning': $warningColorMega,
  'danger': $dangerMega,
  // megaconecta
  'mega_service_recargas': #f8f4ff,
  'mega_service_llamadas': #eef8f8,
  'mega_service_nauta': #fef6ef,
  'mega_service_comida': #eff9fe,
  'mega_bg_footer': #213240,
  // service pages
  'mega_serv_page_llamadas': #36c1c1,
  'mega_serv_page_recargas': #cf92bf,
  'mega_serv_page_nauta': #ff9855,
  'mega_serv_page_combo': #6ed888,
  'mega_serv_page_sms': #d6d66e,
  'mega_light_gray': $mega_light_gray,
  'paypal_color': #2d7fc2,

  'mega_secondary_2': #c3e6bd,
  'mega_gray_c5': #c5c5c5,
  'mega_gray_light': #ebebeb,
  'mega_danger': #ff4b55,
  'mega_danger_2': #ffdbe6,
  'mega_light_pink': $mega_light_pink,
  'info_light': #d4e1fd,

  'mega_approved': #d0f1df,
  'mega_pending': #fff0ae,
  'mega_returned': #e2e2e2,
  'mega_returned_color': #616161,
  'mega_en_camino': #d4e1fdba,
);

$theme-colors-contracts: (
  'green': (
    'light': #e1eee7,
    'dark': #8ab934,
    'shadow-from': #ffffff45,
    'shadow-to': #8ab93459,
  ),
  'blue': (
    'light': #eef3fe,
    'dark': #5586f7,
    'shadow-from': #ffffff45,
    'shadow-to': #5586f759,
  ),
  'orange': (
    'light': #ffeee3,
    'dark': #ff9855,
    'shadow-from': #ffffff45,
    'shadow-to': #ff985559,
  ),
  'red': (
    'light': #ffedf2,
    'dark': #fe4a81,
    'shadow-from': #ffffff45,
    'shadow-to': #fe4a8159,
  ),
  'white': (
    'light': #ffffff,
    'dark': #8ab934,
    'shadow-from': #ffffff45,
    'shadow-to': #8ab93459,
  ),
);

$spacer: 1rem !default;
$spacers: (
  -5: $spacer * -2,
  -4: $spacer * -1.5,
  -3: $spacer * -1,
  -2: $spacer * -0.5,
  -1: $spacer * -0.25,
  0: 0,
  1: $spacer * 0.25,
  2: $spacer * 0.5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 2,
  6: $spacer * 2.5,
  7: $spacer * 3,
  8: $spacer * 3.5,
  9: $spacer * 4,
  10: $spacer * 4.5,

  11: $spacer * 5,
  12: $spacer * 5.5,
  13: $spacer * 6,
  14: $spacer * 5.5,
  15: $spacer * 7,
  16: $spacer * 7.5,
  17: $spacer * 8,
  18: $spacer * 8.5
) !default;

.btn-outline-secondary,
.btn-outline-primary {
  --bs-btn-bg: #fff !important;
}

:export {
  slider_bg_llamadas: $slider_bg_llamadas;
  slider_bg_recargas: $slider_bg_recargas;
  slider_bg_nauta: $slider_bg_nauta;
  slider_bg_sms: $slider_bg_sms;
  primary: $primaryColorMega;
  secondary: $secondaryColorMega;
  gray600: $gray600Mega;
  lightSecondary: lighten($secondaryColorMega, 20%);
  danger: $dangerMega;
  mega_light_gray: $mega_light_gray;
  mega_light_gray_active: $mega_light_gray_active;
  mega_light_pink: $mega_light_pink;
}

$utilities: (
  'width': (
    property: width,
    class: w,
    responsive: true,
    values: (
      auto: auto,
      0: 0,
      25: 25%,
      50: 50%,
      75: 75%,
      100: 100%,
    ),
  ),
);
