@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.product_info {
  .product_info_description {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }

  .product_info_detail {
    font-size: 0.8rem;
    color: $mega_light_pink;
    margin-left: 10px;
  }
}

.textWrapper {
  font-size: 12px;
}

.breadcrumb_border {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 0;
  opacity: 1;
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  padding: 20px 5px 20px 20px;
  width: 100%;
  min-width: 0;
  align-items: center;
  justify-content: flex-start;

  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
}

@include media-breakpoint-up(md) {
  .breadcrumb_border {
    border-radius: 15px;
  }
}

.breadcrumb_text {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 5px;
  color: #8f979f;
  display: inline;

  &.active {
    color: #171825;

    white-space: nowrap;
    overflow-x: hidden;
    text-overflow: ellipsis;
  }
}

.breadcrumb_link {
  font-size: 12px;
  margin-left: 10px;
  margin-top: 2px;
  color: #8f979f;
}

.todas {
  width: 22px;
  height: 22px;
}

.pink_square {
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid #fe4a81;
  width: 25px;
  height: 25px;
  text-align: center;
}

.square_text {
  font-size: 25px;
  font-weight: bold;
  line-height: 20px;

  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.quantity {
  font-size: 15px;
  font-weight: bold;
}

.title_quantity {
  font-size: 15px;
  font-weight: bold;
}
