@import '../../../assets/styles/_variables.module';
@import '../../../assets/styles/_imports';

.cost_wrapper {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.bal_wrapper {
  background-color: $mega_light_gray;
  padding-top: 20px;
  padding-bottom: 20px;
  border-radius: 10px;
}

.col_bal {
  display: flex;
  flex-direction: column;
}

.bal_data_row {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 10px;
  margin: 0 10px;

  &:first-child {
    border-bottom: 1px solid $primaryColorMega;
  }

  h3 {
    color: $secondaryColorMega;
  }
}

.inputArea {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 1px 3px #03004717;
  border-radius: 15px;
  opacity: 1;
  padding: 15px;
}

.tabItem {
  cursor: pointer;
  border-bottom: 3px solid #ffffff;
  border-radius: 1px;

  &.activeTab {
    border-bottom: 3px solid $secondaryColorMega;
  }
}

.smsCostLabel {
  font: normal normal bold 1rem/1.688rem 'Inter-Regular';
  letter-spacing: 0px;
}

.smsCost {
  font: normal normal 900 1.25rem/1.688rem 'Inter-Bold';
  letter-spacing: 0px;
  color: $secondaryColorMega;
}

@include media-breakpoint-down(lg) {
  .tabItem {
    width: 50%;
    text-align: center;
  }
}
