.wrapper {
  padding: 5rem 0;
}

.listItemsRecarga {
  list-style-type: none;
  padding-left: 0;

  li {
    margin-bottom: 8px;
  }
}
