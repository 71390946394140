@import '../../../assets/styles/_variables.module';

.logo {
  max-width: 183px;
}

.a_menuItem {
  color: #171825;
  letter-spacing: 0px;
  font: normal normal bold 14px/25px 'Inter-Bold';
  margin: 10px;
}

.a_menuItem_active {
  color: $secondaryColorMega;
  text-decoration: underline;
}

.menu_item_account {
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  justify-content: flex-start;
  align-items: center;
  font-weight: bold;
  padding: 15px;
  border-radius: 8px;
  background-color: map-get($theme-colors, 'mega_secondary_2');

  &:last-child {
    border-right: 0;
  }

  &.menuItemAccountActive {
    background-color: #ffffff;
  }
}
